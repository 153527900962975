import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import { getUserInfo, updateBasicInfoDetail } from "../../../../services/user-basicinfo/user-basicinfo"
import { toast } from "react-toastify"
import { useAuth } from "../../../../Contexts/auth-context"
import { getCountries } from "../../../../services/career/career-service"
import { getAllCity } from "../../../../services/student/student-profile"
import api from "../../../../services/axiosService"
import apiHeader from "../../../../services/apiHeader"
import { Typeahead } from "react-bootstrap-typeahead"
import "react-bootstrap-typeahead/css/Typeahead.css"
const EditBasicInfo = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const [contryData, setCountryData] = useState([])
  const [cityData, setCityData] = useState([])
  const [country_id, setCountryId] = useState(undefined)
  const getAllCountries = async (params) => {
    if (!params) {
      params = { ...params, page_size: 200, page_number: 0 }
    }

    const res = await api.get(
      "country/getAllCountry",

      {
        headers: apiHeader(),
        params: params,
      }
    )

    if (res.data?.data?.records) {
      setCountryData(res.data?.data?.records)
    }
  }

  const getCountryList = async (payload = {}) => {
    await getAllCountries(payload).then((res) => {
      if (res && res.data?.data) {
        setCountryData(res.data?.data?.records)
      }
    })
  }

  const getAllCities = async () => {
    const res = await getAllCity()
    if (res && res.data?.data?.records) {
      setCityData(res && res.data?.data?.records)
    }
  }
  const getUserInformation = async () => {
    await getUserInfo().then((res) => {
      if (res && res?.data?.data) {
        setFieldValue(res?.data?.data)
        setFieldValue("fname", res?.data?.data?.fname)
        setFieldValue("lname", res?.data?.data?.lname)
        // setFieldValue("fname",res?.data?.data?.fname)
        setFieldValue("designation", res?.data?.data?.designation)
        setFieldValue("gender", res?.data?.data?.gender)
        setFieldValue("home_city", res?.data?.data?.home_address?.city)
        // ✅ Find the country ID based on the country name from user data
        const country = contryData.find((c) => c.name === res?.data?.data?.home_address?.country)
        const countryId = country ? country.id : undefined
        setCountryId(countryId)

        setFieldValue("home_country", res?.data?.data?.home_address?.country)
        setFieldValue("email", res?.data?.data?.email)
        setFieldValue("phone", res?.data?.data?.phone)
        setFieldValue("salutation", res?.data?.data?.salutation)
      }
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      await getAllCountries()
      //setFieldTouched("home_country", true)
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (contryData.length > 0) {
      getUserInformation()
    }
  }, [contryData])

  let validationSchema = Yup.object({
    fname: Yup.string().required("First Name is required"),
    lname: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone is required"),
    home_country: Yup.string().required("Country is required"),
    home_city: Yup.string().required("City is required"),
  })
  const initialValues = {
    fname: "",
    lname: "",
    salutation: "",
    designation: "",
    organization: user?.organization ? user?.organization : "",
    gender: "",
    home_country: "",
    home_city: "",
    email: "",
    phone: "",
  }
  const onSubmit = async () => {
    await Promise.all([
      setFieldTouched("fname", true),
      setFieldTouched("lname", true),
      setFieldTouched("email", true),
      setFieldTouched("phone", true),
      setFieldTouched("home_country", true), // ✅ Ensure this triggers validation
      setFieldTouched("home_city", true),
    ])

    let payload = {
      fname: values?.fname,
      lname: values?.lname,
      salutation: values?.salutation,
      designation: values?.designation,
      organization: user?.organization ? user?.organization : "",
      gender: values?.gender,
      home_country: values?.home_country,
      home_city: values?.home_city,
      email: values?.email,
      phone: values?.phone,
    }
    await updateBasicInfoDetail(payload).then((res) => {
      const { status, message } = res?.data?.meta
      if (status) {
        toast.success(message)
        resetForm()
        navigate("/user/personal-info")
      }
    })
  }
  const {
    handleChange,
    handleSubmit,
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    handleBlur,
    resetForm,
    validateForm, // ✅ Add this to force validation on submit
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      await validateForm() // ✅ Forces validation on submit

      let payload = {
        fname: values?.fname,
        lname: values?.lname,
        salutation: values?.salutation,
        designation: values?.designation,
        organization: user?.organization || "",
        gender: values?.gender,
        home_country: values?.home_country,
        home_city: values?.home_city,
        email: values?.email,
        phone: values?.phone,
      }

      if (Object.keys(errors).length === 0) {
        await updateBasicInfoDetail(payload).then((res) => {
          const { status, message } = res?.data?.meta
          if (status) {
            toast.success(message)
            resetForm()
            navigate("/user/personal-info")
          }
        })
      }
    },
    validateOnMount: true,
  })

  const getCities = async () => {
    const res = await getAllCity({ country_id: country_id })
    if (res && res.data?.data?.records) {
      setCityData(res.data?.data?.records)
    }
  }

  useEffect(() => {
    const fetchCities = async () => {
      if (country_id) {
        await getCities()
        setTimeout(() => {
          setFieldTouched("home_city", true) // ✅ Validate after data is set
        }, 500) // Allow some time for state updates
      }
    }

    fetchCities()
  }, [country_id])

  return (
    <React.Fragment>
      <div className="flex-1">
        <div className="d-flex justify-content-between align-items-start pt-4 mt-2 mb-4">
          <div className="welcome-title d-flex flex-wrap align-items-center">
            <p onClick={() => navigate("/user/personal-info")} className="btn-circle-back"></p>
            <h1 className="ms-3 m-0 text-start">Update Basic Information</h1>
          </div>
        </div>
        <div className="row sd_formsec bg-white m-0 mb-4">
          <div className="d-flex flex-column justify-content-between align-items-center mx-auto max-630 pt-4 pt-sm-5 pb-5 px-0 aos-init aos-animate">
            <form className="lstCustForm w-100" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <label className="form-label m-0 mb-2 font-16 font-500 w-100">Salutation</label>
                  <div className="row">
                    <div className="col-md-2 col-sm-3 col-auto mb-30">
                      <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                        <input type="radio" name="salutation" id="salutation1" value="Dr" onChange={(e) => handleChange(e)} checked={values.salutation === "Dr"} className="form-check-input custom-border-84-20 m-0" />
                        <label className="form-check-label font-16 font-500 ms-2">Dr.</label>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-3 col-auto mb-30">
                      <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                        <input type="radio" name="salutation" id="salutation2" value="Mr" onChange={(e) => handleChange(e)} checked={values.salutation === "Mr"} className="form-check-input custom-border-84-20 m-0" />
                        <label className="form-check-label font-16 font-500 ms-2">Mr</label>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-3 col-auto mb-30">
                      <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                        <input type="radio" name="salutation" id="salutation3" value="Ms" onChange={(e) => handleChange(e)} checked={values.salutation === "Ms"} className="form-check-input custom-border-84-20 m-0" />
                        <label className="form-check-label font-16 font-500 ms-2">Ms</label>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-3 col-auto mb-30">
                      <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                        <input type="radio" name="salutation" id="salutation4" value="Mrs" onChange={(e) => handleChange(e)} checked={values.salutation === "Mrs"} className="form-check-input custom-border-84-20 m-0" />
                        <label className="form-check-label font-16 font-500 ms-2">Mrs</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-30">
                  <label className="form-label m-0 mb-1 font-16 font-500 w-100">First Name</label>
                  <input type="text" className="form-control flex-1" id="First Name" name="fname" error={errors.fname} value={values.fname} onChange={(e) => handleChange(e)} placeholder="Ex: Ashish" />
                  {touched.fname && errors.fname ? <div className="text-danger">{errors.fname}</div> : null}
                </div>
                <div className="col-md-6 mb-30">
                  <label className="form-label m-0 mb-1 font-16 font-500 w-100">Last Name</label>
                  <input type="text" className="form-control flex-1" id="Last Name" name="lname" error={errors.lname} value={values.lname} onChange={(e) => handleChange(e)} placeholder="Ex: Saxena" />
                  {touched.lname && errors.lname ? <div className="text-danger">{errors.lname}</div> : null}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-30">
                  <label className="form-label m-0 mb-1 font-16 font-500 w-100">Designation:</label>
                  <input type="text" className="form-control flex-1" id="designation" name="designation" error={errors.designation} value={values.designation} onChange={(e) => handleChange(e)} placeholder="Ex: School Counselor" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label className="form-label m-0 mb-1 font-16 font-500 w-100">Gender:</label>
                  <div className="row">
                    <div className="col-md-2 col-sm-3 col-auto mb-30">
                      <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                        <input type="radio" name="gender" id="gender_male" value="Male" onChange={(e) => handleChange(e)} checked={values.gender === "Male"} className="form-check-input custom-border-84-20 m-0" />
                        <label className="form-check-label font-16 font-500 ms-2">Male</label>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-3 col-auto mb-30">
                      <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                        <input type="radio" name="gender" id="gender_female" value="Female" onChange={(e) => handleChange(e)} checked={values.gender === "Female"} className="form-check-input custom-border-84-20 m-0" />
                        <label className="form-check-label font-16 font-500 ms-2">Female</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-30">
                  <label className="form-label m-0 mb-1 font-16 font-500 w-100">Which country do you presently reside in?</label>

                  <Typeahead
                    id="home_country"
                    labelKey="name"
                    options={contryData}
                    placeholder="Choose a country..."
                    selected={contryData.filter((x) => x.name === values.home_country)}
                    onChange={(e) => {
                      setFieldValue("home_country", e[0]?.name || "")
                      setCountryId(e[0]?.id)
                      setFieldTouched("home_country", true) // ✅ Triggers validation on selection
                    }}
                    onInputChange={(search) => getCountryList({ search })}
                    onBlur={() => setFieldTouched("home_country", true)} // ✅ Triggers validation on blur
                  />
                  {touched.home_country && errors.home_country ? <div className="text-danger">{errors.home_country}</div> : null}
                </div>
                <div className="col-md-6 mb-30">
                  <label className="form-label m-0 mb-1 font-16 font-500 w-100">Current City of Residence</label>

                  <Typeahead
                    id="home_city"
                    labelKey="name"
                    options={cityData}
                    placeholder="Choose city preference..."
                    selected={cityData.filter((x) => x.name === values.home_city)}
                    onChange={(e) => {
                      setFieldValue("home_city", e[0]?.name || "")
                      setFieldTouched("home_city", true) // ✅ Triggers validation on selection
                    }}
                    onBlur={() => setFieldTouched("home_city", true)} // ✅ Triggers validation on blur
                  />

                  {(touched.home_city || Object.keys(errors).length > 0) && errors.home_city ? <div className="text-danger">{errors.home_city}</div> : null}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-30">
                  <label className="form-label m-0 mb-1 font-16 font-500 w-100">Your Contact Email</label>
                  <input type="text" className="form-control flex-1" id="MinExp" name="email" value={values.email} error={errors.email} onChange={(e) => handleChange(e)} placeholder="Ex: abc@xyz.com" />
                  {touched.email && errors.email ? <div className="text-danger">{errors.email}</div> : null}
                </div>
                <div className="col-md-6 mb-30">
                  <label className="form-label m-0 mb-1 font-16 font-500 w-100">Mobile number (with country code)</label>
                  <div className="row g-2">
                    <div className="col-auto">
                      <select className="form-select form-control country-code" aria-label="Default select example" required="" name="phone">
                        <option>+91</option>
                        <option value="Option1">+92</option>
                        <option value="Option2">+93</option>
                        <option value="Option3">+94</option>
                        <option value="Option4">+95</option>
                      </select>
                    </div>
                    <div className="col">
                      <input type="text" className="form-control" id="PhoneNumber" name="phone" value={values.phone} onChange={(e) => handleChange(e)} placeholder="Ex: 7701898761" />
                      {touched.phone && errors.phone ? <div className="text-danger">{errors.phone}</div> : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-end align-items-center">
                <div className="my-2">
                  <button
                    type="submit"
                    onClick={() => {
                      setFieldTouched("home_country", true)
                    }}
                    className="btn btn-green max-200 text-white mx-auto d-block height-50"
                  >
                    <span>Update</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditBasicInfo
