import React from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../Contexts/auth-context"

const CounsellerHeader = () => {
  const navigate = useNavigate()
  const { user, setUser } = useAuth()
  const companyDetail = JSON.parse(localStorage.getItem("companyDetails"))
  console.log(companyDetail, user)
  const logout = () => {
    localStorage.removeItem("user")
    localStorage.removeItem("token")
    setUser(null)
    setTimeout(() => {
      navigate("/user-login")
    }, 1000)
  }
  return (
    <header className="superCounsellorHeader">
      <div className="container max-1140">
        <div className="d-flex justify-content-between align-items-center custom-header">
          <div className="univarsity_logo manager_profile">
            <div className="logo">
              <button className="mobileonly btn-offcanvas">
                <span className="navbar-toggler-icon">
                  <b>#</b>
                </span>
              </button>
              <span onClick={() => navigate("/user/dashboard?type=ug")} className="cursor-pointer">
                <img
                  src={companyDetail?.logo}
                  alt="counseller"
                  style={{
                    height: "100px",
                    width: "200px",
                    padding: "10px 0",
                    borderRadius: "10px",
                  }}
                />
              </span>
            </div>
          </div>
          {/* <div className="search">
            <form className="form-inline d-flex flex-wrap justify-content-between">
              <button
                className="btn btn-search icon icon-search"
                type="submit"
              ></button>
              <input
                className="form-control flex-1 font-16 font-500"
                type="search"
                placeholder="Try Searching “Top Engineering Colleges in India”"
              />
            </form>
            <span className="btn-search-toggle icon-search"></span>
          </div> */}
          <div className="headerRightSide">
            <div className="mobile-search">
              <span className="btn-search-mobile-toggle icon-search"></span>
            </div>
            <ul>
              {/* <li>
                <span className="calender">
                  <i className="icon-calendar"></i>
                </span>
              </li> */}

              <li className="dropdown">
                <div className="username d-flex align-items-center position-relative">
                  <label className="">
                    {user?.profile_pic ? (
                      <span className="userimg position-relative">
                        <img src={user?.profile_pic} alt="video-thumb" />
                      </span>
                    ) : user?.fname || user?.lname ? (
                      <div data-initials={`${user?.fname?.[0]?.toUpperCase() || ""}${user?.lname?.[0]?.toUpperCase() || ""}`}></div>
                    ) : null}
                    <span className="desktoponly">
                      {user?.fname} {user?.lname}
                    </span>
                  </label>
                  <div className="user-dropdown">
                    <ul>
                      <li>
                        <span onClick={() => navigate("/user/personal-info")} className="cursor-pointer">
                          <b className="icon-main-user me-2"></b>
                          Your Profile
                        </span>
                      </li>
                      {/* <li>
                        <span onClick={() => navigate("/user-reset-password")}>
                          <b className="icon-lock me-2"></b>
                          Change Password
                        </span>
                      </li> */}
                      <li>
                        <span onClick={() => logout()} className="cursor-pointer">
                          <b className="icon-switch me-2"></b>
                          Logout
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light custom-nav offcanvas offcanvas-start" id="offcanvasMobile">
        <ul className="navbar-nav mx-auto d-flex justify-content-center" id="custom-nav">
          <li className="mobileonly">
            <button type="button" className="btn-close text-reset"></button>

            <div className="profileSection mt-3">
              <div className="profileImg">
                <img src="/assets/images/manager-profile.jpg" className="rounded-circle img-fluid" alt="Mr. Shyam Tulsyan - Success Manager" width="40" />
              </div>
              <div className="profileNamePosition">
                <div className="name">Mr. Shyam Tulsyan</div>
                <div className="position">Success Manager</div>
              </div>
            </div>
            <ul></ul>
          </li>
          <li className="nav-item dropdown active">
            <span className="nav-link dropdown-toggle">
              <i className="icon icon-student"></i>
              <span>Dashboard</span>
            </span>
            <ul className="collapse" id="journey-collapse">
              <li onClick={() => navigate("/user/dashboard?type=ug")} className="cursor-pointer">
                <span>UG Aspirants</span>
              </li>
              {/* <li
                onClick={() => navigate("/user/dashboard?type=pg")}
                className="cursor-pointer"
              >
                <span>PG Aspirants</span>
              </li> */}
              {/* <li
                onClick={() => navigate("/user/products")}
                className="cursor-pointer"
              >
                <span>Products</span>
              </li> */}
            </ul>
          </li>
          <li className="nav-item dropdown">
            <span className="nav-link dropdown-toggle">
              <i className="icon icon-student"></i>
              <span>Manage</span>
            </span>
            <ul className="collapse" id="connect-collapse">
              {/* <li onClick={() => navigate("/user/student-list?type=ug")}>
                <span>UG Aspirants</span>
              </li>
              <li onClick={() => navigate("/user/student-list?type=pg")}>
                <span>PG Aspirants</span>
              </li> */}
              <li onClick={() => navigate("/user/student-list")}>
                <span>Student</span>
              </li>
            </ul>
          </li>

          <li className="nav-item dropdown">
            <span className="nav-link dropdown-toggle">
              <i className="icon icon-explore"></i>
              <span>Track</span>
            </span>
            <ul className="collapse" id="track-collapse">
              {/* <li>
                <span>Psychometric Assessment Progress</span>
              </li> */}
              <li onClick={() => navigate("/user/inventory")}>
                <span>Product Inventory</span>
              </li>
              {/* <li>
                <span>Product Usage</span>
              </li> */}
            </ul>
          </li>
          <li className="nav-item dropdown">
            <span className="nav-link dropdown-toggle">
              <i className="icon icon-connect"></i>
              <span>Resources</span>
            </span>
            <ul className="collapse" id="resources-collapse">
              <li onClick={() => navigate("/user/college-list/?activeTab=top")} className="cursor-pointer">
                <span>Top Colleges</span>
              </li>
              <li onClick={() => navigate("/user/college-list/?activeTab=compare")} className="cursor-pointer">
                <span>College Compare</span>
              </li>
              <li onClick={() => navigate("/user/course-fee")}>
                <span>Fee Calculator</span>
              </li>
              <li onClick={() => navigate("/user/career-trends")}>
                <span>Career Trends</span>
              </li>
              <li onClick={() => navigate("/user/entrance-exam")}>
                <span>Entrance Exams</span>
              </li>
              <li onClick={() => navigate("/user/scholarship-search")}>
                <span>Scholarships</span>
              </li>
              <li onClick={() => navigate("/user/career-list")}>
                <span>Career Deep Dive</span>
              </li>
              {/* <li>
                <span>Skills</span>
              </li> */}
              {/* <li onClick={() => navigate("/user/products")}>
                <span>Products</span>
              </li> */}
            </ul>
          </li>
          <li className="nav-item dropdown">
            <span className="nav-link dropdown-toggle">
              <i className="icon icon-events"></i>
              <span>Meetings & Events</span>
            </span>
            <ul className="collapse" id="event-collapse" data-bs-parent="#custom-nav">
              <li onClick={() => navigate("/user/meetings")} className="cursor-pointer">
                <span>Upcoming</span>
              </li>
              <li onClick={() => navigate("/user/meetings/?activeTab=past")} className="cursor-pointer">
                <span>Past</span>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default CounsellerHeader
