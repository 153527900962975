import React, { useState, useRef, useEffect } from "react"
import "./ChatBot.css"
import { sendPromptToGemini, sendPromptToChatGPT } from "./chatBotService"
import Introduction from "./Introduction"
import DefaultPrmopt from "./DefaultPrmopt"
import api from "../../services/axiosService"
import apiHeader from "./../../services/apiHeader"

const ChatBot = () => {
  const [inputPrompt, setInputPrompt] = useState("")
  const [promptHistory, setPromptHistory] = useState([])
  const lastMessageRef = useRef(null) // Reference to the last message

  const [isGeminiTyping, setIsGeminiTyping] = useState(false) // Track Gemini's typing

  const chatContainerRef = useRef(null) // Reference to the chat container

  // Career Counseling related keywords
  const restrictedKeywords = ["abuse", "abusive", "hate", "violence", "offensive", "racism", "sexism", "harassment", "bully", "bullying", "curse", "profanity", "insult", "slur", "threat", "kill", "murder", "suicide", "terror", "terrorism", "racist", "sexist", "homophobic", "bigot", "discrimination", "derogatory", "nasty", "violent", "swear", "swearing", "explicit", "obscene", "inappropriate", "harm", "attack", "harmful", "slander", "libel", "vulgar", "disrespect", "obscene", "threaten", "aggressive", "cruel", "hurt", "demean", "defame", "mock", "assault"]

  // Check if the question contains restricted keywords as exact words
  const isNotCareerRelated = (question) => {
    const lowerCaseQuestion = question.toLowerCase()
    return restrictedKeywords.some((keyword) => {
      const regex = new RegExp(`\\b${keyword}\\b`, "i")
      return regex.test(lowerCaseQuestion)
    })
  }

  // Scroll to the latest message instead of the entire container
  const scrollToLatestMessage = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" })
    }
  }

  const getChatHistoryOfUSerOrStudent = async () => {
    try {
      const res = await api.get("ask-ai/get-user-chat", {
        headers: apiHeader(),
      })
      if (Array.isArray(res?.data?.data)) {
        setPromptHistory(res.data?.data)
      } else {
        setPromptHistory([]) // fallback to empty array if the response is not an array
      }
    } catch (error) {
      console.error("Error fetching chat history", error)
      setPromptHistory([]) // fallback in case of error
    }
  }

  useEffect(() => {
    getChatHistoryOfUSerOrStudent()
  }, [])

  useEffect(() => {
    scrollToLatestMessage() // Scroll to the latest message after promptHistory changes
    if (Array.isArray(promptHistory) && promptHistory.length > 0) {
      saveUserchat(promptHistory)
    }
  }, [promptHistory])

  // const formatResponseText = (text) => {
  //   const formattedText = text
  //     .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
  //     .replace(/\*(.*?)\*/g, "<em>$1</em>")
  //     .replace(/- (.*?)\n/g, "<li>$1</li>")
  //     .replace(/\n\n/g, "<br><br>")
  //     .replace(/(?:\n)+/g, "<br>")
  //   return formattedText
  // }

  const formatResponseText = (text) => {
    // Convert bullet points into list items
    let formattedText = text
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>") // Bold
      .replace(/\*(.*?)\*/g, "<em>$1</em>") // Italic
      .replace(/- (.*?)(?:\n|$)/g, "<li>$1</li>") // Bullet points to <li>
      .replace(/\n\n/g, "<br><br>") // Double newline to <br>
      .replace(/\n/g, "<br>") // Single newline to <br>

    // Wrap lists in <ul> if they exist
    if (formattedText.includes("<li>")) {
      formattedText = formattedText.replace(/(<li>.*?<\/li>)/gs, "<ul>$1</ul>")
    }

    return formattedText
  }

  const getDate = () => {
    const date = new Date()
    const timeString = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
    const dateString = date.toLocaleDateString([], {
      day: "2-digit",
      month: "long",
      year: "numeric",
    })

    return `${timeString} | ${dateString}`
  }

  const handleSendMessage = async () => {
    if (inputPrompt.trim()) {
      setInputPrompt("")
      const userPromptBody = { role: "user", content: inputPrompt, time: getDate() }
      setPromptHistory((previousHistory) => [userPromptBody, ...previousHistory])

      // Check if the input question is career-related
      if (isNotCareerRelated(inputPrompt)) {
        const unrelatedPromptResponse = {
          role: "system",
          content: "I specialize in Career Counseling and can answer only related questions. Feel free to ask more about career paths, universities, or courses.",
          time: getDate(),
        }
        setPromptHistory((previousHistory) => [unrelatedPromptResponse, ...previousHistory])
        return
      }

      // Set Gemini typing indicator
      setIsGeminiTyping(true)
      try {
        const geminiResponse = await sendPromptToChatGPT(inputPrompt, promptHistory)
        const geminiPromptResponseBody = {
          role: "system",
          //content: formatResponseText(geminiResponse),
          content: formatResponseText(geminiResponse),
          time: getDate(),
        }
        setPromptHistory((previousHistory) => [geminiPromptResponseBody, ...previousHistory])
      } catch (error) {
        console.error("Error fetching Gemini response:", error)
      } finally {
        setIsGeminiTyping(false) // Remove typing indicator after response
      }
    }
  }

  // Modify handleKeyDown to trigger the new function on Enter key
  const handleKeyDown = async (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault()
      handleSendMessage()
    }
  }

  const saveUserchat = async (promptHistory) => {
    const res = await api.post(
      "ask-ai/save-user-chat",
      { promptHistory },
      {
        headers: apiHeader(),
      }
    )
  }

  const handleDefaultPromptSelection = async (defaultPrompt) => {
    const userPromptBody = { role: "user", content: defaultPrompt, time: getDate() }
    setPromptHistory((previousHistory) => [userPromptBody, ...previousHistory])
    setIsGeminiTyping(true)
    const geminiResponse = await sendPromptToChatGPT(defaultPrompt)
    const geminiPromptResponseBody = { role: "system", content: formatResponseText(geminiResponse), time: getDate() }
    setPromptHistory((previousHistory) => [geminiPromptResponseBody, ...previousHistory])
    setIsGeminiTyping(false)
  }

  return (
    <div className="ai-chat-wrap bg-white">
      <div className="ai-chat">
        <div className="alumni_chat_block">
          <div className="alumni_chat_block_header">
            <div className="row g-3 align-items-center position-relative z1">
              <div className="col-auto">
                <div className="ai-img">
                  <img className="img-fluid" src="https://s3.ap-south-1.amazonaws.com/media.univariety.com/sfe/ai-super-counselor.svg" alt="AI Avatar" />
                </div>
              </div>
              <div className="col">
                <h3 className="font-30 font-600 mb-0">Ask SIA</h3>
                <p className="font-500 mb-0">Your Favourite Super Counsellor!</p>
              </div>
            </div>
            <i className="icon-chat-invert"></i>
          </div>
          {Array.isArray(promptHistory) && promptHistory.length === 0 && (
            <>
              <Introduction />
              <DefaultPrmopt handleDefaultPromptSelection={handleDefaultPromptSelection} />
            </>
          )}

          <div ref={chatContainerRef} className="coversation-block y-scroll flex-1 d-flex align-items-center p-4">
            {Array.isArray(promptHistory) &&
              promptHistory?.map((message, index) => (
                <div key={index} ref={index === 0 ? lastMessageRef : null} className={`user-chat w-100 mt-3 `}>
                  <div className={`row g-1 ${message.role === "user" ? "justify-content-end" : "justify-content-start"} align-items-end`}>
                    {message.role === "system" && (
                      <div className="col-auto mb-4 pb-1">
                        <div className="ai-img">
                          <img className="img-fluid" src="https://s3.ap-south-1.amazonaws.com/media.univariety.com/sfe/ai-super-counselor.svg" alt="AI Avatar" />
                        </div>
                      </div>
                    )}
                    <div className="col col-sm-8">
                      <div className="d-flex flex-column">
                        <div className={message.role === "user" ? "blue-block" : "liteblue-border-block"}>{message.role === "system" ? <p dangerouslySetInnerHTML={{ __html: message.content }} /> : <p>{message.content}</p>}</div>
                        <div className="date mt-1">{message?.time}</div>
                      </div>
                    </div>
                    {message.role === "user" && (
                      <div className="col-auto mb-4 pb-1">
                        <div className="ai-img">
                          <i className="icon-user position-relative mx-1"></i>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}

            {/* Typing indicator while Gemini is processing */}
            {isGeminiTyping && (
              <div className="user-chat w-100 mt-3">
                <div className="row g-1 justify-content-start align-items-end">
                  <div className="col-auto mb-4 pb-1">
                    <div className="ai-img">
                      <img className="img-fluid" src="https://s3.ap-south-1.amazonaws.com/media.univariety.com/sfe/ai-super-counselor.svg" alt="AI Avatar" />
                    </div>
                  </div>
                  <div className="col col-sm-8">
                    <div className="d-flex flex-column">
                      <div className="liteblue-border-block">
                        {/* Pen-moving/typing animation */}
                        <div className="typing-indicator">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="alumni_chat_conversation_send">
            <div className="row g-2">
              <div className="col-auto">
                <button type="button" className="btn position-relative m-0 p-0">
                  <i className="icon-emoji"></i>
                </button>
              </div>
              <div className="col">
                <div className="position-relative">
                  <textarea disabled={isGeminiTyping} className="form-control h-auto" value={inputPrompt} onChange={(e) => setInputPrompt(e.target.value)} onKeyDown={handleKeyDown} id="msgsend" placeholder={isGeminiTyping ? "Thinking..." : "Ask me anything about career counselling here"} rows="3"></textarea>
                  <button type="button" className="btn position-absolute bottom-10 right-10 m-0 p-0" onClick={handleSendMessage} disabled={isGeminiTyping}>
                    <i className="icon-mail-send position-relative mx-1"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatBot
