import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { updateStudentPassword } from "../../../services/user/student-list/student-list"
import { toast } from "react-toastify"

const ChangePassword = ({ setPasswordChangeModal, passwordChangeModal, studentData, studentName }) => {
  const [passwordData, setPasswordData] = useState("")
  const updatePassword = async (event) => {
    event.preventDefault()
    let payload = {
      student_id: studentData,
      password: passwordData,
    }
    const res = await updateStudentPassword(payload)
    if (res?.data?.meta?.status === true) {
      toast.success("Update SuccessFully")
      setPasswordChangeModal(false)
      setPasswordData("")
    }
  }

  return (
    <Modal show={passwordChangeModal} centered onHide={() => setPasswordChangeModal(false)}>
      {" "}
      <div className="modal-header border-0 d-flex justify-content-between w-full">
        <h5 className="modal-title font-24 text-dark-blue">Update {studentName}'s Password</h5>
        <button type="button" className="btn-close btn-close-small" onClick={() => setPasswordChangeModal(false)}></button>
      </div>
      <div className=" modal-body  g-2 pt-0 mb-2">
        <div className="col mt-2">
          <h5>Password</h5>
          <input type="text" className="form-control" placeholder="password" name="password" required value={passwordData} onChange={(e) => setPasswordData(e.target.value)} />
        </div>
        <button type="submit" className="btn btn-completed radius height-45 max-200 mx-auto d-block w-100 mt-3 font-14 font-500" style={{ background: "#5cae48" }} onClick={updatePassword}>
          <span>Submit</span>
        </button>
      </div>
    </Modal>
  )
}

export default ChangePassword
