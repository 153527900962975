import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { useNavigate } from "react-router-dom"
import { getAllSkills, getPreferenceDetail, updatePreference } from "../../../../services/user-basicinfo/user-basicinfo"
import { toast } from "react-toastify"
import Select from "react-select"

const EditPreferences = () => {
  const navigate = useNavigate()
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState([])
  const [preference, setPreference] = useState()
  const [timeSlots, setTimeslots] = useState()
  const [addTimeBox, setAddTimeBox] = useState([{}])

  const addField = () => {
    const newField = {}
    setAddTimeBox([...addTimeBox, newField])
  }

  const validationSchema = Yup.object({
    uni_update: Yup.string().required("Updates from Univariety is required"),
  })

  const removeField = (i) => {
    let temp = Object.assign([], addTimeBox)
    temp.splice(i, 1)
    setAddTimeBox(temp)
  }
  const initialValues = {
    time_slots: [
      {
        days: [],
        start_time: "",
        end_time: "",
      },
    ],
    uni_rep: true,
    up_skilling: true,
    uni_update: "",
    add_email: "",
    add_phone: "",
    skills: selectedOption,
  }
  const daysName = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
  const handleCheckboxChange = (day, record, index) => {
    let temp = Object.assign([], addTimeBox)
    if (temp[index]["days"]?.length > 0) {
      if (temp[index]["days"].find((x) => x === day)) {
        let i = temp[index]["days"].findIndex((x) => x === day)
        temp[index]["days"].splice(i, 1)
      } else {
        temp[index]["days"].push(day)
      }
      setAddTimeBox(temp)
    } else {
      temp[index]["days"] = []
      temp[index]["days"].push(day)
      setAddTimeBox(temp)
    }
  }

  const onSubmit = async () => {
    let payload = {
      time_slots: addTimeBox,
      uni_rep: values.uni_rep,
      up_skilling: values.up_skilling,
      uni_update: values.uni_update,
      add_email: values.add_email,
      add_phone: values.add_phone,
      skills: selectedOption.map((x) => {
        return x.value
      }),
    }
    await updatePreference(payload).then((res) => {
      const { status, message } = res?.data?.meta
      if (status) {
        toast.success(message)
        resetForm()
        navigate("/user/personal-info")
      }
    })
  }

  const getSkills = async () => {
    try {
      const response = await getAllSkills()
      const skillRecords = response?.data?.data?.records

      // ✅ Map saved skills to match Select options format
      const selectedSkills = skillRecords.map((skill) => ({
        value: skill.id,
        label: skill.name,
      }))
      setSelectedOption(selectedSkills) // ✅ Update selected skills in state
    } catch (error) {
      console.error("Error fetching skills:", error)
    }
  }

  const getPreference = async () => {
    await getPreferenceDetail().then((res) => {
      if (res?.data?.data) {
        setPreference(res.data?.data?.additional)
        setTimeslots(res.data?.data?.time_slots)
        // console.log("preferences ----")
        //console.log(res.data?.data?.additional)
        // Populate addTimeBox with timeSlots from the DB
        // If time slots exist, map them, else initialize with an empty time slot
        const populatedTimeBoxes = res.data?.data?.time_slots?.length
          ? res.data?.data?.time_slots.map((slot) => ({
              days: slot.day.map((day) => day.trim()), // Assuming 'days' is an array
              start_time: slot.time_from,
              end_time: slot.time_to,
            }))
          : [{ days: [], start_time: "", end_time: "" }] // Default empty slot
        console.log("res.data?.data?.time_slots")
        console.log(res.data?.data?.time_slots)
        console.log("populatedTimeBoxes")
        console.log(populatedTimeBoxes)
        setAddTimeBox(populatedTimeBoxes) // Set addTimeBox with DB data

        // ✅ Set Formik Values dynamically
        setValues((prevValues) => ({
          ...prevValues,
          add_email: res.data?.data?.additional?.additional_email || "",
          add_phone: res.data?.data?.additional?.additional_phone || "",
          uni_update: res.data?.data?.additional.updates_interval || "", // Default to empty if not available
        }))

        //setFieldValue("uni_update", res.data?.data?.updates_interval || "") // ✅ Ensures value is set

        // ✅ Mark the field as touched so validation doesn't trigger immediately
        setTouched("uni_update", true, false)
      }
    })
  }

  const handleOptionsChange = (selectedOption) => {
    setSelectedOption(selectedOption)
    setFieldValue(
      "skills",
      selectedOption.map((x) => {
        return x.value
      })
    )
  }

  useEffect(() => {
    getSkills()
    getPreference()
  }, [])

  const { handleChange, handleSubmit, values, setValues, resetForm, setFieldValue, errors, touched, setTouched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  return (
    <React.Fragment>
      <div className="flex-1">
        <div className="d-flex justify-content-between align-items-start pt-4 mt-2 mb-4">
          <div className="welcome-title d-flex flex-wrap align-items-center">
            <p onClick={() => navigate("/user/personal-info")} className="btn-circle-back"></p>
            <h1 className="m-0 text-start ms-3">Update Preferences</h1>
          </div>
        </div>

        <div className="row sd_formsec bg-white m-0 mb-4">
          <div className="d-flex flex-column justify-content-between align-items-center mx-auto max-630 pt-4 pt-sm-5 pb-5 px-0 aos-init aos-animate">
            <form className="lstCustForm w-100" onSubmit={handleSubmit}>
              <div className="row">
                {addTimeBox.map((x, i) => {
                  return (
                    <>
                      <div className="col-md-12" key={i}>
                        <label htmlFor="MinExp" className="form-label m-0 mb-1 font-16 font-500 w-100">
                          Preferred time to get calls from Univariety
                        </label>
                        <div className="row">
                          {daysName?.map((day) => {
                            //console.log(day)
                            const isChecked = addTimeBox[i]?.days?.includes(day) // Check if the day is in the days array for this time slot

                            return (
                              <>
                                <div className="col-auto mb-3" key={day}>
                                  <div className="form-check d-flex flex-wrap pe-4">
                                    <input
                                      className="form-check-input me-2"
                                      name={"days" + i}
                                      type="checkbox"
                                      value={day}
                                      checked={isChecked}
                                      onChange={(e) => {
                                        handleCheckboxChange(day, x, i)
                                      }}
                                    />
                                    <label className="form-check-label font-16 font-500 flex-1 pt-1">{day}</label>
                                  </div>
                                </div>
                              </>
                            )
                          })}
                        </div>
                        <div className="bg-gray p-4 radius-6">
                          <div className="row">
                            <div className="col-sm position-relative">
                              <input
                                type="time"
                                className="form-control flex-1 timepicker"
                                name="start_time"
                                value={x.start_time || ""} // Pre-select the start_time
                                onChange={(e) => {
                                  let temp = Object.assign([], addTimeBox)
                                  temp[i].start_time = e.target.value
                                  setAddTimeBox(temp)
                                }}
                              />
                            </div>
                            <div className="col-sm-auto px-0 py-2 py-sm-0 d-flex align-items-center justify-content-center">To</div>
                            <div className="col-sm position-relative">
                              <input
                                type="time"
                                className="form-control flex-1 timepicker"
                                name="end_time"
                                value={x?.end_time || ""} // Pre-select the end_time
                                onChange={(e) => {
                                  let temp = Object.assign([], addTimeBox)
                                  temp[i].end_time = e.target.value
                                  setAddTimeBox(temp)
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="mb-35 pt-1 me-3">
                          {addTimeBox.length - 1 === i && (
                            <span className="font-16 text-blue-41 timelink cursor-pointer text-decoration-underline" onClick={addField}>
                              <span>+ Add More time Slots</span>
                            </span>
                          )}
                        </div>
                        <div className="mb-35 pt-1">
                          {addTimeBox.length !== 1 && (
                            <span className="font-16 text-blue-41 timelink cursor-pointer text-decoration-underline" onClick={() => removeField(i)}>
                              <span>- Remove Time Slot</span>
                            </span>
                          )}
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>

              <div className="row">
                <div className="col-md-12">
                  <label className="form-label m-0 mb-2 font-16 font-500 w-100" htmlFor="fulltimejobs">
                    Do you wish to connect with university representatives on the platform?
                  </label>
                  <div className="row">
                    <div className="col-auto mb-35">
                      <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center pe-3">
                        <input type="radio" name="uni_rep" checked={preference?.interested_univ_rep == "true" ? true : ""} value={values.uni_rep === true} onChange={(e) => handleChange(e)} className="form-check-input custom-border-84-20 m-0" />
                        <label htmlFor="fulltimejobs1" className="form-check-label font-16 font-500 ms-2">
                          Yes
                        </label>
                      </div>
                    </div>
                    <div className="col-auto mb-30">
                      <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                        <input type="radio" name="uni_rep" checked={preference?.interested_univ_rep == "false" ? true : ""} value={values.uni_rep === false} onChange={(e) => handleChange(e)} className="form-check-input custom-border-84-20 m-0" />
                        <label htmlFor="fulltimejobs2" className="form-check-label font-16 font-500 ms-2">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="form-label m-0 mb-2 font-16 font-500 w-100" htmlFor="fulltimejobs">
                    Interested in upskilling?
                  </label>
                  <div className="row">
                    <div className="col-auto mb-30">
                      <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center pe-3">
                        <input type="radio" checked={preference?.interested_upskills == "true" ? true : ""} name="up_skilling" value={values.up_skilling === true} onChange={(e) => handleChange(e)} className="form-check-input custom-border-84-20 m-0" />
                        <label htmlFor="fulltimejobs1" className="form-check-label font-16 font-500 ms-2">
                          Yes
                        </label>
                      </div>
                    </div>
                    <div className="col-auto mb-30">
                      <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                        <input type="radio" checked={preference?.interested_upskills == "false" ? true : ""} name="up_skilling" value={values.up_skilling === false} onChange={(e) => handleChange(e)} className="form-check-input custom-border-84-20 m-0" />
                        <label htmlFor="fulltimejobs2" className="form-check-label font-16 font-500 ms-2">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-30">
                  <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <label htmlFor="skills" className="form-label m-0 mb-1 font-16 font-500">
                      Which skills would you want to target
                    </label>
                    <span className="font-14 fst-italic text-gray-84">Type and hit "Enter"</span>
                  </div>

                  <Select isMulti name="skills" value={selectedOption} onChange={handleOptionsChange} options={options} />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 mb-30">
                  <label className="form-label m-0 mb-2 font-16 font-500 w-100" htmlFor="fulltimejobs">
                    Updates from Univariety
                  </label>
                  <div className="row">
                    <div className="col-md-3 col-sm-4 col-auto mb-3 mb-sm-0">
                      <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                        <input
                          type="radio"
                          name="uni_update"
                          value="Monthly"
                          checked={values.uni_update === "Monthly"} // ✅ Binds correctly
                          onChange={(e) => setFieldValue("uni_update", e.target.value)}
                          className="form-check-input custom-border-84-20 m-0"
                        />
                        <label htmlFor="Monthly" className="form-check-label font-16 font-500 ms-2">
                          Monthly
                        </label>
                      </div>
                    </div>

                    <div className="col-md-3 col-sm-4 col-auto mb-3 mb-sm-0">
                      <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                        <input type="radio" name="uni_update" value="Quarterly" checked={values.uni_update === "Quarterly"} onChange={(e) => setFieldValue("uni_update", e.target.value)} className="form-check-input custom-border-84-20 m-0" />
                        <label htmlFor="Quarterly" className="form-check-label font-16 font-500 ms-2">
                          Quarterly
                        </label>
                      </div>
                    </div>

                    <div className="col-md-3 col-sm-4 col-auto">
                      <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                        <input type="radio" name="uni_update" value="NotInterested" checked={values.uni_update === "NotInterested"} onChange={(e) => setFieldValue("uni_update", e.target.value)} className="form-check-input custom-border-84-20 m-0" />
                        <label htmlFor="NotInterested" className="form-check-label font-16 font-500 ms-2">
                          Not Interested
                        </label>
                      </div>
                    </div>
                  </div>
                  {touched.uni_update && errors.uni_update ? <div className="text-danger">{errors.uni_update}</div> : null}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-30">
                  <label htmlFor="MinExp" className="form-label m-0 mb-1 font-16 font-500 w-100">
                    Additional Email (optional)
                  </label>
                  <input type="text" className="form-control flex-1" placeholder="" name="add_email" value={values.add_email} onChange={(e) => handleChange(e)} />
                </div>
                <div className="col-md-6 mb-30">
                  <label htmlFor="PhoneNumber" className="form-label m-0 mb-1 font-16 font-500 w-100">
                    Additional Contact Number (optional)
                  </label>
                  <div className="row g-2">
                    <div className="col-auto"></div>
                    <div className="col">
                      <input type="text" className="form-control" placeholder="" name="add_phone" value={values.add_phone} onChange={(e) => handleChange(e)} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-end align-items-center">
                <div className="my-2">
                  <button
                    onClick={(e) => {
                      setTouched("uni_update", true, false)
                    }}
                    type="submit"
                    className="btn btn-green max-200 text-white mx-auto d-block height-50"
                  >
                    <span>Update</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditPreferences
