import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { getProductById, getProductByUserProductId, getTestLink, updateUserProductStarteDate } from "../../../services/products/product-service"

const TestDetails = () => {
  const { id } = useParams()
  const [productDetail, setProductDetail] = useState({})
  const [loading, setLoading] = useState(false)
  const [objData, setObjData] = useState(null) //
  const getProductsDetails = async () => {
    const res = await getProductByUserProductId({ id: id })

    if (res?.data?.meta?.status) {
      console.log("res?.data?.data")
      console.log(res?.data?.data)
      setProductDetail(res?.data?.data)
    }
  }

  useEffect(() => {
    // Include the script dynamically
    if (objData) {
      const script = document.createElement("script")
      script.src = "https://pkg.humanesources.com/js/@humanesources.com/assessment-client@pre/dist/assessment-client.umd.js"
      script.async = true

      script.onload = () => {
        // Initialize the assessment client after the script is loaded
        window.assessmentClient.main({
          apiEnvironment: "production",
          configurationId: "100",
          userId: "10216534",
          apiToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MDQwOTQyMDEsImV4cCI6MTcwNDA5NzgwMSwicm9sZXMiOlsiUk9MRV9BUElfVVNFUiJdLCJpZCI6IjEwMjE2NTM0In0.4WvcO0QokbhklJeFeNowOIW0n8YZzwIpwLO7YPEhIQ8",
          assessmentId: "11985423",
          locale: "en-US",
          locales: ["es-ES", "en-US"],
          displayCareers: true,
          displayBadging: true,
          enableReportBlocking: true,
          displayActivities: true,
          customCssUrl: "https://yourdomain.com/css/customcss.css",
          displayJobLinks: true,
          isoCountryCode: "US",
          stateAbbreviation: "FL",
          readOnly: false,
        })
      }

      document.body.appendChild(script)

      return () => {
        // Cleanup if needed
        document.body.removeChild(script)
      }
    }
  }, [objData])

  const takeTest = async () => {
    setLoading(true)
    if (productDetail?.report_url) {
      window.open(productDetail?.report_url)
      setLoading(false)
      return
    }
    if (productDetail?.vendor == "brain_wonder") {
      const res = await getTestLink({ user_product_id: id })
      setLoading(false)

      if (res?.data?.meta?.status) {
        window.open(res?.data?.data?.data?.test_url, "_blank")
      }
    } else {
      const res = await getTestLink({ user_product_id: id })
      if (res?.data?.meta?.status) {
        if (productDetail?.started_at == null) {
          // call api to set started at to current  date time
          const started_at = new Date().toISOString()
          await updateUserProductStarteDate({ assesment_id: productDetail?.assesment_id, started_at: started_at })
        }
        window.open(res?.data?.data?.data?.test_url, "_blank")
        // let url =
        //   "take-test/key-to-success?configurationId=" +
        //   res.data.data.data.configurationId +
        //   "&userId=" +
        //   res.data.data.data.userId +
        //   "&assessmentId=" +
        //   res.data.data.data.assessmentId +
        //   "&apiToken=" +
        //   res.data.data.data.apiToken;
        // window.open(url, "_blank");
        // setObjData(res?.data?.data?.data);
        // window.assessmentClient.main(res?.data?.data?.data);
      }
    }
  }

  useEffect(() => {
    if (id) {
      getProductsDetails()
    }
  }, [id])

  return (
    <div>
      <div className="main flex-1">
        <div className="max-1140 mx-auto d-flex h-100">
          <div className="flex-1">
            <div className="mt-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="h-100 p-0">
                    <div className="m-w-90 d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-between mt-3 mb-3">
                      <div className="d-flex flex-column flex-1">
                        <h3 className="font-32 font-600 mb-1 ms-3 block-title aos-init aos-animate">{productDetail?.name}</h3>
                      </div>
                      {/* <p className="font-18 font-600 mb-0 me-3 flex-1">
                          Pay ₹2360 to start the test
                        </p> */}

                      <button type="button" className="btn btn-completed radius max-150 height-45  mx-auto d-block w-100 font-14 font-500" style={{ background: "#5cae48" }} onClick={() => takeTest()}>
                        {loading ? (
                          <div className="loader-div">
                            <p className="loader"></p>
                          </div>
                        ) : (
                          <span>{productDetail?.started_at == null ? "Take The Test" : productDetail?.report_url ? "View Report" : "Continue Test"}</span>
                        )}
                      </button>

                      {/* <a
                          className="breadcum_btn btn-sm btn-green"
                          onClick={() => takeTest()}
                        >
                          <span>Take The Test</span>
                        </a> */}
                    </div>

                    <div className="life-skills-course assessment_session mb-5">
                      <div className="life-skills-course-detail">
                        <div className="max-1010 mx-auto">
                          <div className="country-deatils d-flex justify-content-between border-bottom mt-4 mb-4 pb-4 d-inline-block">
                            <div>
                              <h1 className="font-36 font-900 mb-3 text-dark-blue ">About Assessment</h1>
                              <h3 className="font-20 font-400 mb-4 lineHeight-30">{productDetail?.overview}</h3>
                            </div>
                            <div className="round-circle">
                              <img src="https://univariety.s3.ap-south-1.amazonaws.com/img/planet-earth.png" alt="stream" />
                            </div>
                          </div>

                          <div className="tuition-fees border-bottom mb-5 pb-4" id="Universities">
                            <h2 className="font-20 font-600 position-relative title pb-2 mt-4 mb-4">Assessment Output</h2>
                            {/* <p style={{ fontSize: "16px" }}>
                              The test output is in the form of a report which
                              shares a brief about the domains in humanities.
                              The report helps in matching your activity
                              choices, presence of mind, real life situations
                              and personality to professions in humanities. The
                              report will consist of scores on career cluster in
                              humanities, the career options in each cluster and
                              work areas. For your visualization the report will
                              consist an interest graph that will provide you
                              comparison between various domains.
                            </p> */}
                            <ul className="red-tick">
                              {productDetail?.benifits?.map((x, i) => {
                                return (
                                  <li key={i}>
                                    <b>{x.title}</b>
                                    <span>{x.description}</span>
                                  </li>
                                )
                              })}
                            </ul>
                          </div>

                          <div id="visa" className="tuition-fees pb-4">
                            <h2 className="font-20 font-600 position-relative title pb-2 mb-4">Instructions</h2>

                            <div className="basic-requirements">
                              <ul className="green-correct">
                                {productDetail?.instruction?.map((x, i) => {
                                  return <li key={i}>{x?.description}</li>
                                })}
                              </ul>
                            </div>
                          </div>

                          <div id="visa" className="visa mb-0 pb-4">
                            <div className="row justify-content-start justify-content-sm-center">
                              <button type="button" className="btn btn-completed radius max-150 height-45  mx-auto d-block w-100 font-14 font-500" style={{ background: "#5cae48" }} onClick={() => takeTest()}>
                                {loading ? (
                                  <div className="loader-div">
                                    <p className="loader"></p>
                                  </div>
                                ) : (
                                  <span>{productDetail?.started_at == null ? "Take The Test" : productDetail?.report_url ? "View Report" : "Continue Test"}</span>
                                )}
                              </button>

                              {/* <div className="col-auto mb-3">
                                <button
                                  data-ename="Student Clicked on Sample Report - Stream Selector Test"
                                  data-escorecat="Moderate"
                                  data-emodule="Assessment (SU-A)"
                                  data-ecategory="Passive Interaction"
                                  data-escore="2"
                                  type="button"
                                  className="breadcum_btn btn-sm btn-green height-45 px-5"
                                >
                                  <span>View Sample Report</span>
                                </button>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestDetails
