import runGimini from "./Config/gimini"
import apiHeader from "./../../services/apiHeader"
import api from "../../services/axiosService"

export const sendPromptToGemini = async (prompt, history) => {
  const result = runGimini(prompt)
  console.log("Question is:" + prompt)
  console.log("Answer is:" + result)
  return result
}

export const sendPromptToChatGPT = async (question, chatHistory) => {
  const res = await api.post(
    "ask-ai/get-response-from-chatgpt",
    { question, chatHistory },
    {
      headers: apiHeader(),
    }
  )
  return res?.data?.data
}
